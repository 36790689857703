import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import {normalizePath} from '../../utils/get-url-path';

const PossibilityItem = ({title, desc, linkUrl, linkText}) => (
        <div className={'possibility-item visible animated'}>
            <h3 className={'h3-title possibility-title'}>
                <AniLink to={normalizePath(linkUrl)}
                         className={'link-title'}
                >
                    <span className={'title'}
                          data-animated-group="animated-title"
                    >
                    {title}
                </span>
                </AniLink>
            </h3>
            <span className={'separator separator--horizontal'} data-animated-group="animated-title"/>
                <div className={'paragraph-primary__bold possibility-content'}
                     data-animated-group="animated-content"
                >
                    <p dangerouslySetInnerHTML={{__html: desc}}/>
                </div>
            <AniLink to={normalizePath(linkUrl)}
                     paintDrip
                     duration={1}
                     hex="#ed5d2b"
                     className={'possibility-link mc-link mc-button-secondary top-left'}
                     data-animated-group="animated-content"
            >
                <span className={'label'}>{linkText}</span>
            </AniLink>
        </div>
);

export default PossibilityItem;