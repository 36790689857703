import React from 'react';
import {graphql} from 'gatsby';
import Services from '../../components/template-parts/services-listing-part';

export default ({data}) => <Services {...data}/>

export const query = graphql`
    query serviceCategory($id: String!) {
        serviceCategory: wpServiceCategory(id: { eq: $id }) {
            id
            name
            uri
            serviceCategoryFields {
                title
                resultsList {
                    title
                    threeColumns
                    list {
                        description
                    }
                }
                otherCategories {
                    title,
                    subTitle
                    list {
                        title
                        description
                        viewAllText
                        category {
                            id
                            name
                            link
                            description
                        }
                    }
                }
            }
            services {
                nodes {
                    id
                    title
                    uri
                    servicesCustomFields {
                        shortDescription
                    }
                }
            }
            caseStudiesTitleBlock {
                block {
                    titleH2
                    titleH5
                    description
                    linkTarget
                    linkText
                }
            }
        }
        
        caseStudies: allWpCaseStudy(
            limit: 2
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                id
                slug
                title
                content
                link
                contentType {
                    node {
                        graphqlSingleName
                        name
                    }
                }
                caseStudySingleFeatured {
                    additionalFields {
                        titleH5
                        clientFigures
                    }
                }
                caseStudyRegions {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyIndustries {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyTechnologies {
                    nodes {
                        id
                        name
                    }
                }
                info {
                    aboutClient
                    aboutClientShort
                    brand
                    result
                    gridImage {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 70) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                    }
                }
            }
        }
    }
`
