import React, { useState } from 'react';
import AccordionItem from "./accordion-item";

const Accordion = ({
    items = [],
    titlePath = 'title',
    descPath = 'description',
    urlPath = 'url',
}) => {
    const getValue = (item, path) => {
        if (!!item && !!path) {
            if (typeof path === 'string' && path.includes('.')) {
                path = path.split('.');
            }

            if (Array.isArray(path)) {
                const part = path.shift();
                if (path.length > 0) {
                    return getValue(item[part], path);
                }
                path = part;
            }

            if (item.hasOwnProperty(path)) {
                return item[path];
            }
        }
        return undefined;
    };

    const [activeAccordion, setActiveAccordion] = useState(null);

    return (
        <div className={'mc-accordion'}>
            {items.map((item, index) => (
                <AccordionItem key={index}
                               index={index}
                               activeIndex={activeAccordion}
                               setActiveIndex={setActiveAccordion}
                               title={getValue(item, titlePath)}
                               description={getValue(item, descPath)}
                               url={getValue(item, urlPath)}
                />
            ))}
        </div>
    );
};

export default Accordion;