import React from 'react';
import sanitizeHtml from 'sanitize-html';
import Layout from '../layout';
import Accordion from '../accordion';
import MageButton from '../mageButton';
import ServiceResultsList from '../service-results-list';
import TitleLists from '../title-lists';
import PossibilitiesBlock from '../possibilities-block';
import sanitizeOptions from "../../helper/sanitizeOptions";

const Services = ({serviceCategory, caseStudies}) => {
    const sanitizeOpt = sanitizeOptions();
    const page = {
        title: serviceCategory?.serviceCategoryFields?.title,
        howItHelps: serviceCategory?.serviceCategoryFields?.resultsList || {},
        caseStudy: serviceCategory?.caseStudiesTitleBlock?.block || {},
        otherCategories: serviceCategory?.serviceCategoryFields?.otherCategories || {},
    };
    page.otherCategories.list = (page.otherCategories?.list || []).map(serviceCategory => {
        if (serviceCategory.hasOwnProperty('category')) {
            return {
                id: serviceCategory.category.id,
                title: serviceCategory.title || serviceCategory.category.name,
                link: serviceCategory.category.link,
                description: serviceCategory.description || serviceCategory.category.description,
                viewAllText: serviceCategory.viewAllText
            };
        }
        return serviceCategory;
    });

    const services = serviceCategory?.services?.nodes || [];

    return (
        <Layout>
            <div className={'what-we-do-list'}>
                {!!page.title && (
                    <div className={'page-content'}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(page.title, sanitizeOpt.title)}}/>
                )}
                <div className={'page-content what-we-do-list__lets-chat-container'}>
                    <div className={'what-we-do-list__lets-chat-arrows'}>
                        <div><i className={'mc-icon mc-icon--arrow mc-icon--arrow-d'}/></div>
                        <div><i className={'mc-icon mc-icon--arrow mc-icon--arrow-d'}/></div>
                        <div><i className={'mc-icon mc-icon--arrow mc-icon--arrow-d'}/></div>
                        <div><i className={'mc-icon mc-icon--arrow mc-icon--arrow-d'}/></div>
                    </div>
                    <div className={'what-we-do-list__lets-chat-button'}>
                        <MageButton color={`#EC5C2B`} size={190} text={`let's chat`} textColor={`white`}/>
                    </div>
                </div>
                {!!services.length && (
                    <div className={'page-content what-we-do-list__services-container'}>
                        <div className={'what-we-do-list__services-chose'}>
                            <h3 className={'h3-title'}>Choose what is fitting for you</h3>
                            <div>Tailored development solutions that will cater to the needs of your business</div>
                            <MageButton color={`#EC5C2B`} size={190} text={`let's chat`} textColor={`white`}/>
                        </div>
                        <div className={'what-we-do-list__services-list'}>
                            <Accordion
                                items={services}
                                descPath={'servicesCustomFields.shortDescription'}
                                urlPath={'uri'}
                            />
                        </div>
                    </div>
                )}
                <div className={'page-content'}>
                        <ServiceResultsList
                            title={page.howItHelps.title}
                            threeColumns={page.howItHelps.threeColumns}
                            list={page.howItHelps.list}
                        />
                    <div className={'case-studies-block-container'}>
                        <TitleLists data={{
                            customFields: 'case_studies_block',
                            titleH2: page.caseStudy.titleH2,
                            titleH5: page.caseStudy.titleH5,
                            description: page.caseStudy.description,
                            link: page.caseStudy.linkTarget,
                            button: page.caseStudy.linkText,
                            caseStudies: caseStudies,
                        }}/>
                    </div>
                    <PossibilitiesBlock
                        title={page.otherCategories.title}
                        subTitle={page.otherCategories.subTitle}
                        list={page.otherCategories.list}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default Services;