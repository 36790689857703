import React from 'react';
import PossibilityItem from "./possibility-item";

const PossibilitiesBlock = (
    {
        title,
        subTitle,
        children,
        list,
        listKeys = {
            title: 'title',
            description: 'description',
            linkText: 'viewAllText',
            linkUrl: 'link'
        }
    }
) => (Array.isArray(list) && (
    <div className={'possibilities-block'}>
        {!!subTitle && (
            <div className={'possibilities-block__sub-title h5-title'}>{subTitle}</div>
        )}
        <div className={'possibilities-block__grid'}>
            <div className={'possibilities-block__title-container'}>
                {!!title && (
                    <h3 className={'possibilities-block__title h3-title'}>{title}</h3>
                )}
                {!!children && (
                    <div className={'possibilities-block__content'}>{children}</div>
                )}
            </div>
            {list.map((item, index) => (
                <PossibilityItem
                    key={index}
                    title={item[listKeys.title]}
                    desc={item[listKeys.description]}
                    linkText={item[listKeys.linkText]}
                    linkUrl={item[listKeys.linkUrl]}
                />
            ))}
        </div>
    </div>
));

export default PossibilitiesBlock;