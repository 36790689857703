import React, {useEffect, useState} from "react";
import ServiceResultsItems from "./items";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import TrackVisibility from 'react-on-screen';

const ServiceResultsListAnimation = ({isVisible, title, threeColumns, list, fieldGroupName}) => {
    const sanitizeOpt = sanitizeOptions();
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <>
            {Array.isArray(list) && (
                <div className={`service-results-list${threeColumns ? ' service-results-list--three' : ''}`}>
                    {!!title && (
                        <div style={{
                            overflow: 'hidden'
                        }}>
                            <h2
                                className={'service-results-list__title h2-title'}
                                dangerouslySetInnerHTML={{__html: sanitizeHtml(title, sanitizeOpt.title)}}
                                style={{
                                    transition: 'all .75s ease',
                                    transform: `translateY(${startAnimation ? 0 : '-100%'})`
                                }}
                            />
                        </div>
                    )}
                    <div className={'service-results-list__list'}>
                        {list.map((item, index) => (
                            <ServiceResultsItems
                                index={index}
                                item={item}
                                key={index}
                                startAnimation={startAnimation}
                            />
                        ))}
                    </div>
                </div>
            )
            }
        </>
    )
};

const ServiceResultsList = ({title, threeColumns, list, fieldGroupName}) => {
    return (
    <TrackVisibility once offset={500}>
        <ServiceResultsListAnimation
            title={title}
            threeColumns={threeColumns}
            list={list}
            fieldGroupName={fieldGroupName}
        />
    </TrackVisibility>
    );
};

export default ServiceResultsList;
