import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {normalizePath} from '../../utils/get-url-path';

const AccordionItem = (
    {
        title,
        description,
        url,
        index,
        activeIndex,
        setActiveIndex,
    }
) => {
    const keyDown = (ev) => {
        if (ev.keyCode === 13) {
            setActiveIndex(index);
        }
    };

    return (
        <div className={'mc-accordion-item'}>
            {(index !== activeIndex) && (
                <div className={'mc-accordion-item__title-container'}
                     onClick={() => setActiveIndex(index)}
                     onKeyDown={keyDown}
                     role={'button'}
                     tabIndex={index}
                >
                    <div className={'mc-accordion-item__title'}>{title}</div>
                    <i className={'mc-icon mc-icon--arrow mc-icon--arrow-ld'}/>
                </div>
            )}
            {(index === activeIndex) && (<>
                <div className={'mc-accordion-item__title-container mc-accordion-item__title-container--active'}>
                    <AniLink
                        className={'mc-accordion-item__title mc-accordion-item__title--active'}
                        to={normalizePath(url)}
                    >{title}</AniLink>
                    <i className={'mc-icon mc-icon--arrow mc-icon--arrow-r'}/>
                </div>
                {!!description && (
                    <div className={'mc-accordion-item__description'}>{description}</div>
                )}
            </>)}
        </div>
    );
};

export default AccordionItem;