import React from 'react';

const ServiceResultsItems = ({startAnimation, item, index}) => {
    return (
        <div
            key={index}
            className={'service-results-list__item'}
            style={{
                opacity: startAnimation? 1 : 0,
                transition: 'all .75s ease'
            }}
        >
            <div className={'service-results-list__index'}>{`0${index + 1}.`.substr(-3)}</div>
            <div className={'service-results-list__desc'}>{item.description}</div>
        </div>
    )
}



export default ServiceResultsItems;
